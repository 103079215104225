import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

//import '../sites/all/themes/tickets/js/_analyticstagmanager';
import '../las-estacas/src/sites/all/themes/tickets/js/_analyticstagmanager'

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(let registration of registrations) {
               if(registration.active.scriptURL == 'https://estacas2021.dev.m22.mx/sw.js'){ registration.unregister(); }
            }
        });
}
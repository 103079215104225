// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atracciones-[node]-js": () => import("./../../../src/pages/atracciones/[node].js" /* webpackChunkName: "component---src-pages-atracciones-[node]-js" */),
  "component---src-pages-atracciones-albercas-js": () => import("./../../../src/pages/atracciones/albercas/[...].js" /* webpackChunkName: "component---src-pages-atracciones-albercas-js" */),
  "component---src-pages-atracciones-balsa-js": () => import("./../../../src/pages/atracciones/balsa/[...].js" /* webpackChunkName: "component---src-pages-atracciones-balsa-js" */),
  "component---src-pages-atracciones-bodas-js": () => import("./../../../src/pages/atracciones/bodas/[...].js" /* webpackChunkName: "component---src-pages-atracciones-bodas-js" */),
  "component---src-pages-atracciones-buceo-js": () => import("./../../../src/pages/atracciones/buceo/[...].js" /* webpackChunkName: "component---src-pages-atracciones-buceo-js" */),
  "component---src-pages-atracciones-grupos-js": () => import("./../../../src/pages/atracciones/grupos/[...].js" /* webpackChunkName: "component---src-pages-atracciones-grupos-js" */),
  "component---src-pages-atracciones-hospedaje-js": () => import("./../../../src/pages/atracciones/hospedaje/[...].js" /* webpackChunkName: "component---src-pages-atracciones-hospedaje-js" */),
  "component---src-pages-atracciones-kayak-js": () => import("./../../../src/pages/atracciones/kayak/[...].js" /* webpackChunkName: "component---src-pages-atracciones-kayak-js" */),
  "component---src-pages-atracciones-lago-infantil-js": () => import("./../../../src/pages/atracciones/lago-infantil/[...].js" /* webpackChunkName: "component---src-pages-atracciones-lago-infantil-js" */),
  "component---src-pages-atracciones-restaurantes-y-bar-js": () => import("./../../../src/pages/atracciones/restaurantes-y-bar/[...].js" /* webpackChunkName: "component---src-pages-atracciones-restaurantes-y-bar-js" */),
  "component---src-pages-atracciones-rio-js": () => import("./../../../src/pages/atracciones/rio/[...].js" /* webpackChunkName: "component---src-pages-atracciones-rio-js" */),
  "component---src-pages-atracciones-snorkeling-js": () => import("./../../../src/pages/atracciones/snorkeling/[...].js" /* webpackChunkName: "component---src-pages-atracciones-snorkeling-js" */),
  "component---src-pages-atracciones-spa-js": () => import("./../../../src/pages/atracciones/spa/[...].js" /* webpackChunkName: "component---src-pages-atracciones-spa-js" */),
  "component---src-pages-atracciones-tirolesa-js": () => import("./../../../src/pages/atracciones/tirolesa/[...].js" /* webpackChunkName: "component---src-pages-atracciones-tirolesa-js" */),
  "component---src-pages-atracciones-yoga-js": () => import("./../../../src/pages/atracciones/yoga/[...].js" /* webpackChunkName: "component---src-pages-atracciones-yoga-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog/[...].js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-hospedajes-js": () => import("./../../../src/pages/hospedajes.js" /* webpackChunkName: "component---src-pages-hospedajes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politicas-y-terminos-de-uso-js": () => import("./../../../src/pages/politicas-y-terminos-de-uso.js" /* webpackChunkName: "component---src-pages-politicas-y-terminos-de-uso-js" */),
  "component---src-pages-promociones-js": () => import("./../../../src/pages/promociones.js" /* webpackChunkName: "component---src-pages-promociones-js" */),
  "component---src-pages-reglamento-js": () => import("./../../../src/pages/reglamento.js" /* webpackChunkName: "component---src-pages-reglamento-js" */),
  "component---src-pages-reserva-js": () => import("./../../../src/pages/reserva.js" /* webpackChunkName: "component---src-pages-reserva-js" */),
  "component---src-pages-sostenibilidad-js": () => import("./../../../src/pages/sostenibilidad.js" /* webpackChunkName: "component---src-pages-sostenibilidad-js" */)
}

